import { Component, OnInit } from '@angular/core';

import { UserService } from '../../services/user.service';
import { StoreService } from '../../services/store.service';
import { UserDevice, UserDevices } from '../../models/user';
import { Platform, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.page.html',
  styleUrls: ['./dash.page.scss']
})

export class DashPage implements OnInit {
  devices: UserDevice[];
  username: string;
  password: string;

  constructor(
    private userService: UserService,
    private store: StoreService,
    private platform: Platform,
    private loadingController: LoadingController
  ) {
    this.devices = [];
    this.platform.ready().then(() => {
      this.loadingController
        .create({
          message: 'Please wait....',
          cssClass: 'my-loading-class'
        })
        .then(loadingElement => {
          loadingElement.present();
          ///
        });
    });
  }

  ngOnInit() {
    this.store.clearUserDevices();
    console.log('Success');
    this.getUserDevices();
  }

  getUserDevices(url: string = null) {
    this.userService.getUserDevices(url)
    .subscribe((data: UserDevices) => {
      if (data.links.next !== undefined) {
        this.getUserDevices(data.links.next);
        this.store.appendUserDevices(data.devices);
      } else {
        this.store.appendUserDevices(data.devices);
        for (const d of this.store.getUserDevices()) {
          if (d.lastUpdated !== null && (d.class === 5 || d.class === 16 || d.class === 21)) {
            this.devices.push(d);
          }
        }
        if (this.devices.length === 0) {
          this.getUserDevices();
        } else {
          console.log('Devices Successfully retrieved');
        }
        //this.loadingController.dismiss();
      }
    },
    error => {
      console.error(error);
    });

    setTimeout(() => {
      this.loadingController.dismiss();
    }, 5000);
  }
}
