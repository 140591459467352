import { Injectable } from '@angular/core';
import { UserDevice, User } from '../models/user';
import { DeviceData, DeviceLocation, DeviceLocationResponse } from '../models/device';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private userDevices: UserDevice[];
  private userData: User;
  private deviceData: DeviceData;
  private deviceLocation: DeviceLocation;

  constructor() {
    this.userDevices = [];
    this.deviceData = {
      deviceId: '', data: []
    };
    this.deviceLocation = {
      deviceId: '', locations: []
    };

    this.userData = null;
  }

  clearUserDevices() {
    this.userDevices = [];
  }

  getUserDevices(): UserDevice[] {
    return [...this.userDevices];
  }

  appendUserDevices(add: UserDevice[]): UserDevice[] {
    this.userDevices = this.userDevices.concat(add);

    return [...this.userDevices];
  }

  clearDeviceData() {
    this.deviceData = {
      deviceId: '', data: []
    };
  }

  getDeviceData(deviceId: string): DeviceData {
    if (this.deviceData.deviceId === deviceId) {
      return {...this.deviceData};
    } else {
      return null;
    }
  }

  appendDeviceData(add: DeviceData): DeviceData {
    if (this.deviceData.deviceId === add.deviceId) {
      this.deviceData.data = this.deviceData.data.concat(add.data);
    } else {
      this.deviceData = {...add};
    }

    return {...this.deviceData};
  }

  getDeviceLocationData(deviceId: string): DeviceLocation {
    if (this.deviceLocation.deviceId === deviceId) {
      return {...this.deviceLocation};
    } else {
      return null;
    }
  }

  appendDeviceLocationData(add: DeviceLocation) {
    if (this.deviceLocation.deviceId === add.deviceId) {
      this.deviceLocation.locations = this.deviceLocation.locations.concat(add.locations);
    } else {
      this.deviceLocation = {...add};
    }

    return {...this.deviceLocation};
  }

  setUserData(user: User) {
    this.userData = {...user};
  }

  getUser(): User {
    return {...this.userData};
  }
}
