import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserId, UserCredentials, UserDevices, UserClusters, UserCluster, UserResponse } from '../models/user';
// import { HttpErrorHandlerService, HandleError } from './http-error-handler.service';

const serverUrl = 'https://hive-backend.com/api/v1/user/';
const userKey = 'HEXAGON';

// let _userService: UserService; // This is to make the handler Error find this element

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // private ignoreError: HandleError;
  private userId: UserId;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    /* private httpErrorHandler: HttpErrorHandlerService */) {
      // this.ignoreError = httpErrorHandler.createHandleError('UserService');
      this.userId = JSON.parse(localStorage.getItem(userKey));
      // console.log(this.userId);
      // _userService = this;
    }

  setUserID(userId: UserId) {
    this.userId = userId;
    localStorage.setItem(userKey, JSON.stringify(userId));
  }

  getUserID(): UserId {
    return this.userId;
  }

  login(username: string, password: string) {
    const url = `${serverUrl}login`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const cred: UserCredentials = {
      username,
      password
    };

    return this.http.post<UserId>(url, cred, httpOptions);
      // .pipe(
      //   retry(2),
      //   catchError(this.ignoreError<UserId>('login', {userId: null}))
      // );
  }

  logout() {
    localStorage.removeItem(userKey);
    window.location.replace('/Login');
  }

  getUserDevices(url: string = null): Observable<UserDevices> {
    if (url === null) {
      url = `${serverUrl}devices`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'HIVE-USER-ID': this.getUserID().userId
      })
    };

    return this.http.get<UserDevices>(url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserData(url: string = null): Observable<UserResponse> {
    if (url === null) {
      url = `${serverUrl}profile`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'HIVE-USER-ID': this.getUserID().userId
      })
    };

    return this.http.get<UserResponse>(url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserClusters(type: number = 0, url: string = null): Observable<UserClusters> {
    if (url === null) {
      if (type === 0) {
        url = `${serverUrl}clusters`;
      } else {
        url = `${serverUrl}clusters?class=${type}`;
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'HIVE-USER-ID': this.getUserID().userId
      })
    };

    return this.http.get<UserClusters>(url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserCluster(id: number): Observable<UserCluster> {
    const url = `${serverUrl}cluster/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'HIVE-USER-ID': this.getUserID().userId
      })
    };

    return this.http.get<UserCluster>(url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserClusterDevices(id: number, url: string = null): Observable<UserDevices> {
    if (url === null) {
      url = `${serverUrl}cluster/${id}/devices`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'HIVE-USER-ID': this.getUserID().userId
      })
    };

    return this.http.get<UserDevices>(url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

 // private handleError(error: HttpErrorResponse) {
   private handleError = (error: HttpErrorResponse)  => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      if (error.status === 401) {
        this.logout();
      }

      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(error.statusText + ', please try again later.');
  }
}
